import React from "react";
import { PageWrapper } from "~components/Core";
import HeaderButton from "~sections/marketing/Header";
import FooterSix from "~components/Footer";
import HeroImage from "../slices/HeroImageBanner/HeroSection";
import DynamicComponent from "~components/dynamicComponent";

import useStoryblok from "../lib/storyblok";
import BlogRegular from "../sections/Blog/BlogRegular";
import { render } from "storyblok-rich-text-react-renderer"

import SbEditable from "storyblok-react";

import { graphql } from "gatsby";
import SEO from "../components/Seo";

const NewsPage = ({data, location}) => { 

  let story = data.storyblokEntry;
  story = useStoryblok(story, location);

  const components = story.content.blocks.map((blok) => {
    return <DynamicComponent blok={blok} key={blok._uid} />;
  });

  const header = {
    headerClasses: `site-header site-header--menu-start  dark-header site-header--sticky`,
    containerFluid: false,
    darkLogo: true,

    // customLogo: Images.HeaderLogo,
    buttonBlock: (
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnOneText="GET STARTED"
        mr="15px"
        mrLG="0"
      />
    ),
  };

  return (
    <PageWrapper headerConfig={header}>
      <SEO title={story.content.seo_title} description={story.content.seo_title}/>
      <SbEditable content={story.content}>{components}</SbEditable>
      <BlogRegular data={data.allStoryblokEntry.edges}/>
      <FooterSix/>
    </PageWrapper>
  );
}

export default NewsPage

export const query = graphql`
  query NewsPage {
    storyblokEntry(full_slug: { eq: "news" }) {
      content
      name
    }
    allStoryblokEntry(filter: {field_component: {eq: "blog"}}) {
      edges {
        node {
          id
          name
          slug
          field_component
          full_slug
          content
          first_published_at(formatString: "DD MMMM, YYYY")
        }
      }
    }
    
  }
`;
