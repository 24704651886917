import React from "react";
import { SuperTag } from "~components";
import Card from "./style";
import { Link } from "gatsby";

export default function BlogCard({
  text,
  title,
  Like,
  date,
  user,
  commentCount,
  badge,
  image,
  url,
  ...rest
}) {
  return (
    <Link to={`/news/${url}`} css={{display: "block"}}>
    <Card>
      <Card.Image>
        <img src={image} alt="Blog" />
      </Card.Image>
      <Card.OvaerlayBlock>
        <Card.Top mb="20px">
          <Card.Date to={`/news/${url}`}>{date}</Card.Date>
        </Card.Top>
        <Card.Title to={`/news/${url}`}>
          {" "}
          <SuperTag value={title} />
        </Card.Title>
      </Card.OvaerlayBlock>
    </Card>

    </Link>
  );
}
