import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Blog from "./style";
import BlogCard from "./Comonent/Card";
import BlogRequlerData from "~data/BlogRequlerData";
import { Images } from "~data";

export default function BlogRegular({data}) {
  return (
    <Blog backgroundColor="white">
      <Container>
        <Row className="justify-content-center">
          {data.map(
            (
              item,
              index
            ) => {
              const obj = JSON.parse(item.node.content);
              return (
                <Col
                  xs="12"
                  className="col-lg-6 col-md-6 col-xs-9"
                  key={"bsp" + index}
                >
                  <BlogCard
                    image={obj.hero.filename}
                    date={item.node.first_published_at}
                    url={item.node.full_slug}
                    title={obj.title}
                  />
                </Col>
              );
            }
          )}
        </Row>
      </Container>
    </Blog>
  );
}
